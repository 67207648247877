import './index.css';
import React, {useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import DATA_CONSULTING from "../../config/data_consulting";
import Modal from "../modal";
import ModalContainer from "../modal_container";

function ListService(props) {
    let service = props.item;
    return (
        <>
            <motion.div className={"col-md-6"} onClick={props.handlerOnClick}>
                <motion.div className={"service-box"}>
                    <div className="service-content">
                        <h5 className="font-alt title">{service.title}</h5>
                        {service.captions.map((item, index) =>
                            <p key={index.toString()} className={"caption"}>{item}</p>
                        )}
                        <p className={"advise"}>{props.t('service_advice')}</p>
                        <div style={{marginTop: '25px'}}>
                            {service.settings.map((item, index) =>
                                <p key={index.toString()} className={"setting"}><label
                                    className="color-brand-dark"
                                    style={{marginRight: '5px'}}>{props.t('service_' + item.key)}:</label>{item.value}
                                </p>
                            )}
                        </div>
                        <div className={"price-content"}>
                            {service.price && service.price.map((item, index) =>
                                <h3 key={index.toString()}
                                    className={"price font-alt"}>{(item.name ?? '') + ' ' + item.value}
                                    <small>{item.iva}</small></h3>
                            )}
                        </div>
                        <motion.button
                            itemID={service.type}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.9}}
                            className={"px-btn bg-brand-green color-brand-white"}
                            onClick={props.handlerOnClick}>{props.t(service.cta)}</motion.button>
                    </div>
                </motion.div>
            </motion.div>
        </>
    )
}

const Services = (props) => {
    const [selectedItem, setSelectedItem] = useState(null);
    return (
        <motion.div className="section section-services bg-brand-white">
            <div className="container">
                <div className={"row"}>
                    <motion.div className="col-md-8 offset-md-2 text-align-center">
                        <motion.div className="title">
                            <motion.h1 className={"has-bullet brand-title-xl"}>{props.t('service_title')}</motion.h1>
                        </motion.div>
                    </motion.div>
                </div>
                <div className={"row"}>
                    <motion.div className="col-md-8 offset-md-2 text-align-center">
                        <motion.p className={"fs-md color-brand-dark"}>{props.t('service_subtitle')}</motion.p>
                    </motion.div>
                </div>
                <div className={"row"}>
                    {DATA_CONSULTING.section_service.item.map((item, index) =>
                        <ListService key={index.toString()}
                                     item={item} t={props.t}
                                     handlerOnClick={() => setSelectedItem(item)}/>
                    )}
                    <ModalContainer>
                        {selectedItem && (<Modal item={selectedItem} handleClose={() => setSelectedItem(null)} t={props.t}/>)}
                    </ModalContainer>
                </div>
            </div>
        </motion.div>
    );
}

export default Services;