import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import home_it from "./translations/it/home.json";
import consulting_it from "./translations/it/consulting.json";
import home_en from "./translations/en/home.json";
import consulting_en from "./translations/en/consulting.json";

const root = ReactDOM.createRoot(document.getElementById('root'));

i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: 'it',                              // language to use
    resources: {
        en: {
            home: home_en,
            consulting: consulting_en
        },
        it: {
            home: home_it,
            consulting: consulting_it
        },
    },
}).then(r => i18next);

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </React.StrictMode>
);

//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
