import './index.css';
import React from 'react';
import Slider from "react-slick";

const TESTIMONIAL_LIST = [
    {
        avatar_image: 'https://media-exp1.licdn.com/dms/image/C4D03AQFLPWGzKjFOew/profile-displayphoto-shrink_100_100/0/1622220266854?e=1656547200&v=beta&t=f_80LvxqNU2cAhTF_mZYWyxjno31mqYyewNoS0LxB-8',
        text: 'Manuel is not only an intelligent and ambitious person but also an inspiring colleague. As I remember, Manuel was a very productive person, is hardworking, broad-minded and forward thinking individual. Intelligent, ambitious, energetic and proactive perfectionist. Always maintains excellent relations with colleagues and customers, focusing on details and customer satisfaction. Manuel is a reliable professional. Highly recommended.',
        owner: 'Alessandro Cislaghi',
        job_title: "ICT Manager"
    },
    {
        avatar_image: 'https://media-exp1.licdn.com/dms/image/C4D03AQF3hfzHk1pBAw/profile-displayphoto-shrink_100_100/0/1626266848261?e=1656547200&v=beta&t=OQYsu0gViULvdg2p0el8Xr0AlpDHvYKNCDfi45kuV-s',
        text: 'Manuel is a stimulating colleague with whom to work and learn new production methods. He is a very productive, efficient, intuitive and far-sighted person. Working with him I can say that he is a perfectionist and intelligent professional. Skilled in comparing with colleagues and finding suitable solutions in different situations and professional areas.',
        owner: "Gianmario Deriù",
        job_title: "Co-founder & CEO @ Crazy Adv Srl - Entrepreneur"
    },
    {
        avatar_image: 'https://media-exp1.licdn.com/dms/image/C4D03AQHjaEvYCc1yIA/profile-displayphoto-shrink_100_100/0/1594888636156?e=1656547200&v=beta&t=0ZkJwK3g5dhQs612TNsQxdRGUfMb4xNMKXNx7c65CLM',
        text: 'Manuel worked in my group for about 3 years. He is an excellent element, collaborative and always ready for new challenges. Even in difficulties he managed to instill calm and optimism in the group. It was a great experience',
        owner: "Paolo Guolo",
        job_title: "Marketplace Specialist presso Mela"
    }
]

const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function ListTestimonial(props) {
    return (
        <div>
            <div tabIndex="-1">
                <div className="testimonial-01 media">
                    <div className="avatar">
                        <img src={props.avatar_image} alt="review comments"/>
                    </div>
                    <div className="media-body">
                        <p>{props.text}</p>
                        <div className={"author"}>
                            <h6 className={"font-alt"}>{props.owner}</h6>
                            <span className={"font-alt"}>{props.job_title}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Reference = () => {
    return (
        <div className="testimonial-wrapper">
            <Slider {...SliderSettings}>
                {
                    TESTIMONIAL_LIST.map((item, index) =>
                        <ListTestimonial key={index.toString()}
                                         avatar_image={item.avatar_image}
                                         text={item.text}
                                         owner={item.owner}
                                         job_title={item.job_title}/>
                    )
                }
            </Slider>
        </div>
    );
}

export default Reference;