import './index.css';
import React from "react";
import Navigation from "../navigation";
import {motion} from "framer-motion";
import Features from "../features";
import Services from "../services";
import {useTranslation} from "react-i18next";
import Clients from "../clients";
import Footer from "../footer";
import BIO_COPY from "../../config/data_biography";

const Consulting = () => {
    const {t, i18n} = useTranslation('consulting');
    return (
        <div className={"main-left theme-dark"}>
            <Navigation fixed={"consulting"}/>
            <section id={"consulting"} className={"banner banner-two bg-brand-orange"}>
                <div className="container clearfix">
                    <div className="row full-screen align-items-center">
                        <div className="column col-lg-12">
                            <div className={"type-box"}>
                                <motion.div animate={{opacity: 1}}
                                            initial={{opacity: 0}}
                                            transition={{duration: 0.5, delay: 0.5}}>
                                    <div className={"x0 x1 x2"}>
                                        <div>
                                            <div className={"x0 x3 x4 x5"}>
                                                <p className={"loop-text lead font-alt color-brand-dark"}
                                                   style={{textTransform: 'inherit'}}>{t('presentation')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.h1 className={"font-alt motto"}
                                           animate={{opacity: 1}}
                                           initial={{opacity: 0}}
                                           transition={{duration: 0.5, delay: 0.7}}>{t('motto')}</motion.h1>
                                <motion.p className={"desc h-width color-brand-dark fs-md"}
                                          animate={{opacity: 1}}
                                          initial={{opacity: 0}}
                                          transition={{duration: 0.5, delay: 0.8}}>{t('first_paragraph')}</motion.p>
                                <motion.p className={"desc h-width color-brand-dark fs-md"}
                                          animate={{opacity: 1}}
                                          initial={{opacity: 0}}
                                          transition={{duration: 0.5, delay: 0.8}}>{t('second_paragraph')}</motion.p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Features t={t}/>
            <div className="bg-brand-white" data-negative="false">
                <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="elementor-shape-fill " d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"
                          style={{'fill': '#0e0f17'}}/>
                </svg>
            </div>
            <Services t={t}/>
            <Clients/>
            <Footer data={BIO_COPY.about} t={t}/>
        </div>
    )
}

export default Consulting;