import './index.css';
import React from 'react';
import Slider from "react-slick";
import {motion} from "framer-motion";

const SliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 1500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};

const CLIENTS_LIST = [
    {
        image: 'https://manuelbraghin.com/images/clients/creative_cables.jpeg',
        name: 'Creative Cables',
        link: 'https://www.creative-cables.it/content/52-chi-siamo',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/giada_baldi.png',
        name: 'Giada Baldi Photographer',
        link: 'https://www.giadabaldi.com/',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/jungle_team.png',
        name: 'Jungle Team SRL',
        link: 'https://jungleteam.it/',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/enoi.jpeg',
        name: 'ENOI SPA',
        link: 'https://www.linkedin.com/company/enoi-s.p.a.',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/nano_sistemi.png',
        name: 'Nano Sistemi SRL',
        link: 'https://www.nanosistemi.com/azienda/',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/intesa_san_paolo.jpeg',
        name: 'Intesa San Paolo SPA',
        link: 'https://www.intesasanpaolo.com/',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/italcementi.jpeg',
        name: 'Italcementi SPA',
        link: 'https://www.italcementi.it/it',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/nanalab.jpeg',
        name: 'NanaLab SRL',
        link: 'https://www.linkedin.com/company/nanalab-s.r.l./about/',
    },
    {
        image: 'https://manuelbraghin.com/images/clients/idea_coworking.png',
        name: 'IDEA Coworking & Hub',
        link: 'https://ideacoworking.it/',
    },
]

function ListClient({item}) {
    return (
        <div className="client-1">
            <div className="avatar">
                <a href={item.link} target={"_blank"} rel={"noreferrer"}><img src={item.image} alt={item.name}/></a>
            </div>
        </div>
    )
}

const Clients = () => {
    return (
        <motion.div className="section bg-brand-white">
            <div className="clients-wrapper">
                <Slider {...SliderSettings}>
                    {CLIENTS_LIST.map((item, index) =>
                        <ListClient key={index.toString()} item={item}/>
                    )}
                </Slider>
            </div>
        </motion.div>
    );
}

export default Clients;