import './index.css';
import React from 'react';
import {Link} from 'react-router-dom';
import {faHomeUser, faUser, faRss, faBriefcase, faStar, faCertificate, faEnvelopeCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MB_LOGO from '../../utility/manuelbraghin-logo-icon.png';

const NAV_LIST = [
    {href: "#home", layout: "Home", type:'scroll'},
    {href: "#about", layout: "About", type:'scroll'},
    {href: "#project", layout: "Project", type:'scroll'},
    {href: "#skill", layout: "Skill", type:'scroll'},
    {href: "#contact", layout: "Contact", type:'scroll'},
    {href: "/consulting", layout: "Consulting", type:'link_in'},
    {href: "https://bragholand.com/", layout: "Blog", type: 'link_out'}
]

function ListNav(props) {
    let nav_fixed = props.fixed ?? undefined;
    let css;
    let icon;
    switch (props.layout) {
        case 'Home': css = nav_fixed === undefined ? 'active' : ''; icon = <FontAwesomeIcon icon={faHomeUser} className={"icon"}/>; break;
        case 'About': icon = <FontAwesomeIcon icon={faUser} className={"icon"}/>; break;
        case 'Project': icon = <FontAwesomeIcon icon={faBriefcase} className={"icon"}/>; break;
        case 'Skill': icon = <FontAwesomeIcon icon={faStar} className={"icon"}/>; break;
        case 'Contact': icon = <FontAwesomeIcon icon={faEnvelopeCircleCheck} className={"icon"}/>; break;
        case 'Consulting': css = nav_fixed === 'consulting' ? 'active' : ''; icon = <FontAwesomeIcon icon={faCertificate} className={"icon"}/>; break;
        case 'Blog': css = 'bg-brand-green'; icon = <FontAwesomeIcon icon={faRss} className={"icon"}/>; break;
        default: css = ''; break;
    }

    let p_href = props.href;
    if (props.type === 'scroll' && props.fixed === 'consulting') {
        p_href = '/' + p_href;
    }

    return (
        <li>
            {
                {
                    'scroll': <Link className={"nav-link " + css} to={p_href} onClick={props.onClick}>{icon}<span className="item">{props.layout}</span></Link>,
                    'link_in': <Link className={"nav-link " + css} to={p_href} onClick={props.onClick}>{icon}<span className="item">{props.layout}</span></Link>,
                    'link_out': <a className={"nav-link bg-brand-green"} href={p_href} target="_blank" rel="noreferrer">{icon}<span className="item">{props.layout}</span></a>
                }[props.type]
            }
        </li>
    );
}

const Navigation = (props) => {

    function toggleBurgerMenu() {
        document.querySelector('header[data-nav=right]').classList.toggle('menu-open');
    }

    function toggleSectionMenu(e, item) {
        let anchor_item = '#' + item.toLowerCase();

        let element = document.querySelector(anchor_item);
        element.scrollIntoView({ behavior: 'smooth', block: 'start'});

        console.log('current anchor: ' + anchor_item);
        let nav = document.querySelectorAll('.nav-link'); //.map().classList.toggle('active');
        for (let i = 0; i < nav.length; ++i) {
            nav[i].classList.remove('active');
            console.log('current hash: ' + nav[i].hash);
            if (nav[i].hash === '#/' + anchor_item) {
                nav[i].classList.toggle('active');
            }
        }
    }

    return (
        <>
            <div className="mob-header" onClick={toggleBurgerMenu}>
                <button className="toggler-menu">
                    <div><span/><span/><span/></div>
                </button>
            </div>
            <header data-nav={"right"} className="header-left header-menu-style-two">
                <div className="scroll-bar">
                    <div className="hl-top">
                        <div className="hl-logo">
                            <Link to={"/"}>
                                <img src={MB_LOGO} title={"Manuel Braghin - Full Stack Developer & Digital Technology Strategist"}  alt={"Manuel Braghin - Full Stack Developer & Digital Technology Strategist"}/>
                            </Link>
                        </div>
                    </div>
                    <ul className="nav nav-menu">
                        {
                            NAV_LIST.map((item, index) =>
                                <ListNav key={index.toString()}
                                         fixed={props.fixed ?? null}
                                         type={item.type}
                                         href={item.href}
                                         layout={item.layout}
                                         onClick={(e) => toggleSectionMenu(e, item.layout)}/>
                            )
                        }
                    </ul>
                </div>
            </header>
        </>
    );
}

export default Navigation;