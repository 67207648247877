import './index.css';
import React from 'react';
import {faMapMarked, faEnvelope, faCertificate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const Contact = (props) => {

    return (
        <section id="contact" className={"section"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xs-4 ml-auto m-15px-tb">
                        <div className="contact-info">
                            <h4 className={"font-alt"}>{props.t('contact.title')}</h4>
                            <h4 className={"font-alt"}>{props.t('contact.in_touch')}</h4>
                            <p>{props.t('contact.caption')}</p>
                            <ul>
                                <li className="media">
                                    <FontAwesomeIcon icon={faEnvelope} className={"icon"}/>
                                    <a className="media-body text" href={"mailto:hola@manuelbraghin.com"} target="_blank" rel="noreferrer">hola@manuelbraghin.com</a>
                                </li>
                                <li className="media">
                                    <FontAwesomeIcon icon={faMapMarked} className={"icon"}/>
                                    <a className="media-body text" href={"https://ideacoworking.it/"} target="_blank" rel="noreferrer">IDEA Coworking & Hub</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7 col-xs-8 ml-auto m-15px-tb">
                        <div className="consulting-box ">
                            <h4 className={"color-brand-orange font-alt"}>{props.t('contact.business.title')}</h4>
                            <h5>{props.t('contact.business.sub_title')}</h5>
                            <p>{props.t('contact.business.caption')}</p>
                            <div className="btn-bar">
                                <Link to={"/consulting"} className="px-btn button-brand-orange">
                                    <FontAwesomeIcon icon={faCertificate} className={"icon"} spin/> {props.t('contact.business.cta')}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="google-map">
                            <div className="embed-responsive embed-responsive-21by9">
                                <iframe className="embed-responsive-item" title="Manuel Braghin | Office IDEA Coworking & Hub"
                                        loading="lazy"
                                        allowFullScreen
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11279.398618635509!2d7.8031037!3d45.0279774!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdf4538b51cece548!2sIDEA%20coworking%20%26%20Hub!5e0!3m2!1sit!2sit!4v1651063334641!5m2!1sit!2sit">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;