import './index.css';
import React from "react";
import {motion} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const PageNotFound = (props) => {
    return (
        <div>
            <h3>
                Pagina non trovata
            </h3>
        </div>
    );
}

export default PageNotFound;