import './index.css'
import React from 'react';
import {motion} from 'framer-motion';
import BIO_COPY from "../../config/data_biography";
import Navigation from "../navigation";
import About from "../about";
import Project from "../project";
import Skill from "../skill";
import Contact from "../contact";
import Footer from "../footer";
import {useTranslation} from "react-i18next";
import {faCertificate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const Home = () => {
    const {t, i18n} = useTranslation('home');

    return (
        <div className={"main-left theme-dark"}>
            <Navigation/>
            <section id="home" className="banner banner-two">
                <div className="container clearfix">
                    <div className="row full-screen align-items-center">
                        <div className="column col-lg-12">
                            <div className={"type-box"}>
                                <motion.h6 className={"font-alt"}
                                           animate={{opacity: 1}}
                                           initial={{opacity: 0}}
                                           transition={{duration: 0.5, delay: 0.5}}>{t('hello')}</motion.h6>
                                <motion.h1 className={"font-alt"}
                                           animate={{opacity: 1}}
                                           initial={{opacity: 0}}
                                           transition={{duration: 0.5, delay: 0.7}}>{BIO_COPY.name_complete}</motion.h1>
                                <motion.div animate={{opacity: 1}}
                                            initial={{opacity: 0}}
                                            transition={{duration: 0.5, delay: 0.75}}>
                                    <p className="loop-text lead font-alt">{BIO_COPY.job_title}</p>
                                </motion.div>
                                <motion.p className={"desc"}
                                          animate={{opacity: 1}}
                                          initial={{opacity: 0}}
                                          transition={{duration: 0.5, delay: 0.8}}>{t(BIO_COPY.mission)}</motion.p>
                                <motion.div className="mt-4 content-cta-home"
                                            animate={{opacity: 1}}
                                            initial={{opacity: 0}}
                                            transition={{duration: 0.5, delay: 1.25}}>
                                    <motion.a className="px-btn px-btn-white"
                                              target="_blank" rel="noreferrer"
                                              href={require("../../utility/cv/Manuel_Braghin_2022.pdf")}>{t('download.cv')}</motion.a>
                                    <label>oppure</label>
                                    <Link className={"px-btn bg-brand-orange color-brand-white"} to={"/consulting"}>
                                        <FontAwesomeIcon icon={faCertificate} className={"icon"} spin/>{t('hire.me')}
                                    </Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <About data={BIO_COPY.about} t={t}/>
            <Project t={t}/>
            <Skill t={t}/>
            <Contact t={t}/>
            <Footer data={BIO_COPY.about} t={t}/>
        </div>
    );
}

export default Home;