import './index.css';
import React from 'react';

const SKILL_LIST_FE = [
    {title: "React JS", value: 91},
    {title: "Vue Js", value: 81},
    {title: "HTML5 + CSS + JS", value: 97},
    {title: "Solidity", value: 77},
];

const SKILL_LIST_BE = [
    {title: "PHP (+ Frameworks)", value: 95},
    {title: "Node JS", value: 83},
    {title: ".NET Core", value: 89},
    {title: "Python", value: 79}
];

const SKILL_SOFT = [
    {title: "Problem Solver", value: 95},
    {title: "Goal Oriented", value: 99},
    {title: "Time Management", value: 81},
    {title: "Self Discipline", value: 88}
];

function ListSkill(props) {
    return (
        <div className="skill-lt">
            <h6 className={"font-alt"}>{props.title}</h6>
            <span className="count-inner font-alt"><span>{props.value}</span>%</span>
            <div className="skill-bar">
                <div className="skill-bar-in" style={{width: props.value + "%"}}/>
            </div>
        </div>
    )
}

const Skill = (props) => {

    return (
        <section id="skill" className={"section theme-light dark-bg"}>
            <div className="container">
                <div className="title"><h3>Skills.</h3></div>
                <div className="row align-items-center">
                    <div className="col-lg-6 ml-auto m-15px-tb">
                        <div className="skill-wrapper mb-3 mt-4">
                            {
                                SKILL_LIST_BE.map((item, index) =>
                                    <ListSkill key={index.toString()} title={item.title} value={item.value}/>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 ml-auto m-15px-tb">
                        <div className="skill-wrapper mb-3 mt-4">
                            {
                                SKILL_LIST_FE.map((item, index) =>
                                    <ListSkill key={index.toString()} title={item.title} value={item.value}/>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="separated"/>
                <div className="row align-items-center">
                    <div className="col-lg-5 m-auto m-15px-tb">
                        <div className="education-box">
                            <h5 className={"font-alt"}>{props.t('skill.title')}</h5>
                            <p>{props.t('skill.description_1')}</p>
                            <p>{props.t('skill.description_2')}</p>
                            <p>{props.t('skill.description_3')}</p>
                            <p>{props.t('skill.description_4')}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1  ml-auto m-15px-tb">
                        <div className="skill-wrapper mb-3 mt-4">
                            {
                                SKILL_SOFT.map((item, index) =>
                                    <ListSkill key={index.toString()} title={item.title} value={item.value}/>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skill;