import './index.css';
import React, {useState} from "react";
import {motion} from "framer-motion";
import DATA_CONSULTING from "../../config/data_consulting";
import Modal from "../modal";
import ModalContainer from "../modal_container";

function ListFeature({index, item, t}) {
    let feature_title = 'features.' + index + '_title';
    let feature_description = 'features.' + index + '_description';
    return (
        <div className="features__item">
            <div className="features__content">
                <h2 className="features__title font-alt">{t(feature_title)}</h2>
                <p className="features__text font-monospace">{t(feature_description)}</p>
            </div>
            <div className="features__image-wrap">
                <img src={item.image} alt={t(feature_title)} className="entered"/>
            </div>
        </div>
    )
}

const Features = (props) => {
    const FeatureConsulting = {
        type: 'consulting',
        title: 'Consulenza'
    };
    const [selectedItem, setSelectedItem] = useState(null);
    return (
        <motion.div className="section section-features bg-brand-dark-light">
            <div className="container">
                <div className={"row"}>
                    <motion.div className="col-md-8 offset-md-2 text-align-center">
                        <motion.div className="title">
                            <motion.h1
                                className={"has-bullet brand-title-xl"}>{props.t('features.title')}</motion.h1>
                        </motion.div>
                        <motion.p className={"fs-md color-brand-white"}>{props.t('features.subtitle')}</motion.p>
                    </motion.div>
                </div>
                <div className={"row row-features"}>
                    <div className={"section__content mb-100"}>
                        <div className="features features--homepage features--bg-none">
                            {DATA_CONSULTING.section_feature.item.map((item, index) =>
                                <ListFeature key={index.toString()} item={item} t={props.t} index={index + 1}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <motion.div className="col-md-12 text-align-center">
                        <motion.div className="title">
                            <motion.h1 className="brand-title-md">{props.t('features.question_cta')}</motion.h1>
                        </motion.div>
                        <motion.div className="btn-bar">
                            <motion.button className="px-btn btn-md button-brand-green"
                                           onClick={() => setSelectedItem(FeatureConsulting)}>{props.t('features.cta')}</motion.button>
                        </motion.div>
                        <ModalContainer>
                            {selectedItem && (
                                <Modal item={selectedItem} handleClose={() => setSelectedItem(null)} />)}
                        </ModalContainer>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}

export default Features;