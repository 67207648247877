import './index.css';
import React from 'react';
import {motion} from 'framer-motion';
import LOGO_MALABO from '../../utility/malabo_logo.png';
import LOGO_GLOOWE from '../../utility/gloowe_logo.png';
import LOGO_BENTERTAINMENT from '../../utility/bragho_entertainment_logo.png';
import LOGO_SWELLOOP from '../../utility/swelloop_logo.png';
import LOGO_BEMRK from '../../utility/beffect_marketing_logo.png';

const PROJECT_LIST = [
    {node: "malabo", title: "Malabo Agency", job_location: "Turin | Remote", job_duration: "2022 - present", job_schedule: "24/7"},
    {node: "gloowe", title: "Gloowe", job_location: "Turin | Remote", job_duration: "2017 - present", job_schedule: "24/7"},
    {node: "be_marketing", title: "BEffect Marketing", job_location: "Turin | Remote", job_duration: "2016 - 2018", job_schedule: "Full Time"},
    {node: "swelloop", title: "Swelloop.com", job_location: "Turin | Remote", job_duration: "2013 - 2015", job_schedule: "Full Time"},
    {node: "bragho_entertainment", title: "Bragho Entertainment", job_location: "Turin | Remote", job_duration: "2010 - 2013", job_schedule: "Full Time"}
];

function ListProject(props) {
    return (
        <motion.div className={"resume-box col-xl-6 col-md-6"}
             animate={{opacity: 1}}
             initial={{opacity: 0}}
             transition={{duration: 0.5, delay: 0.5}}>
            <div className={"resume-row"}>
                <div className="row">
                    <div className="col-md-4 col-xl-3">
                        <div className="rb-left">
                            {
                                {
                                    'malabo':<div className={"avatar"}><img src={LOGO_MALABO} alt={"Logo " + props.title}/></div>,
                                    'gloowe':<div className={"avatar"}><img src={LOGO_GLOOWE} alt={"Logo " + props.title}/></div>,
                                    'be_marketing':<div className={"avatar"}><img src={LOGO_BEMRK} alt={"Logo " + props.title}/></div>,
                                    'swelloop':<div className={"avatar"}><img src={LOGO_SWELLOOP} alt={"Logo " + props.title}/></div>,
                                    'bragho_entertainment':<div className={"avatar"}><img src={LOGO_BENTERTAINMENT} alt={"Logo " + props.title}/></div>
                                }[props.node]
                            }
                            <div className={"details"}>
                                <div className={"item"}>
                                    <label className={"font-alt"}>{props.job_location}</label>
                                    <p className={"font-alt"}>{props.job_duration}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                        <div className="rb-right">
                            <h6 className={"font-alt"}>{props.title}</h6>
                            <p>{props.translation('project.' + props.node)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

const Project = (props) => {

    return (
        <section id="project" className={"section"}>
            <div className="container">
                <div className="title"><h3>Projects.</h3></div>
                <div className={"row masonry-container"}>
                    {
                        PROJECT_LIST.map((item, index) =>
                            <ListProject key={index.toString()}
                                         job_location={item.job_location}
                                         job_duration={item.job_duration}
                                         job_schedule={item.job_schedule}
                                         title={item.title}
                                         node={item.node}
                                         translation={props.t}/>
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default Project;