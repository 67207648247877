import React, {Suspense} from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./components/home";
import Consulting from "./components/consulting";
import PageNotFound from "./components/404";

const App = () => {
    return (
        <Suspense fallback="loading">
            <HashRouter>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/consulting" element={<Consulting/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
            </HashRouter>
        </Suspense>
    );
}

export default App;
