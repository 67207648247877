import './index.css';
import React from "react";
import {motion} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAward, faBox, faDesktop, faLayerGroup, faMobile, faUsers} from '@fortawesome/free-solid-svg-icons';
import Reference from "../reference";

const WHATIDO_LIST = [
    {href: 'https://www.facebook.com/', type: 'mobile', node: 'app_developer'},
    {href: 'https://www.facebook.com/', type: 'desktop', node: 'cms_developer'},
    {href: 'https://www.facebook.com/', type: 'wp', node: 'web_developer'},
    {href: 'https://www.facebook.com/', type: 'blockchain', node: 'blockchain_dev'},
    {href: 'https://www.facebook.com/', type: 'consulting', node: 'web_consulting'},
    {href: 'https://www.facebook.com/', type: 'digital_strategist', node: 'digital'}
];

function ListSocial(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {
                {
                    'facebook': <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512"
                                     height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
                    </svg>,
                    'instagram': <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                                      height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                    </svg>,
                    'linkedin': <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                                     height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/>
                    </svg>,
                    'twitter': <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512"
                                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
                    </svg>,
                    'telegram': <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 20 240 220"
                                     height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M66.964 134.874s-32.08-10.062-51.344-16.002c-17.542-6.693-1.57-14.928 6.015-17.59 7.585-2.66 186.38-71.948 194.94-75.233 8.94-4.147 19.884-.35 14.767 18.656-4.416 20.407-30.166 142.874-33.827 158.812-3.66 15.937-18.447 6.844-18.447 6.844l-83.21-61.442z"
                            fill="none" stroke="#fff" strokeWidth="10"/>
                        <path d="M92.412 201.62s4.295.56 8.83-3.702c4.536-4.26 26.303-25.603 26.303-25.603" fill="none"
                              stroke="#fff" strokeWidth="10"/>
                        <path
                            d="M66.985 134.887l28.922 14.082-3.488 52.65s-4.928.843-6.25-3.613c-1.323-4.455-19.185-63.12-19.185-63.12z"
                            fillRule="evenodd" stroke="#fff" strokeWidth="10" strokeLinejoin="bevel"/>
                        <path d="M66.985 134.887s127.637-77.45 120.09-71.138c-7.55 6.312-91.168 85.22-91.168 85.22z"
                              fillRule="evenodd" stroke="#fff" strokeWidth="9.67" strokeLinejoin="bevel"/>
                    </svg>
                }[props.type]
            }
        </a>
    )
}

function ListWhatIDo(props) {
    let translate_key_title = 'what_i_do.' + props.node + '_title';
    let translate_key_description = 'what_i_do.' + props.node + '_description';
    return (
        <motion.div className="col-md-6 col-lg-4 my-3">
            <motion.div className="feature-box-01"
                        animate={{opacity: .85, background: '#010101'}}
                        transition={{type: 'spring', stiffness: 50}}
                        whileHover={{scale: 1.055, opacity: 1, background: '#ff9301'}}>
                <motion.div className="icon">
                    {
                        {
                            'mobile': <FontAwesomeIcon icon={faMobile}/>,
                            'desktop': <FontAwesomeIcon icon={faDesktop}/>,
                            'wp': <FontAwesomeIcon icon={faAward}/>,
                            'blockchain': <FontAwesomeIcon icon={faLayerGroup}/>,
                            'consulting': <FontAwesomeIcon icon={faUsers}/>,
                            'digital_strategist': <FontAwesomeIcon icon={faBox}/>,
                        }[props.type]
                    }
                </motion.div>
                <motion.div className="feature-content">
                    <motion.h5 className={"font-alt"}>{props.translation(translate_key_title)}</motion.h5>
                    <motion.p>{props.translation(translate_key_description)}</motion.p>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

function ListBioContact(props) {
    let p_type = props.type ?? 'text';
    return (
        <div className="col-sm-6 mb-2">
            <label className={"mr-1 font-alt"}>{props.t(props.text)}</label>
            {
                {
                    'text': <span>{props.value}</span>,
                    'link': <a href={props.link ?? '#'} target="_blank" rel="noreferrer">{props.value}</a>
                }[p_type]
            }
        </div>
    )
}

const About = (props) => {

    let about_me_image = props.data && props.data.image ? props.data.image : "https://nairo.ibthemespro.com/img/about/about-me-2.jpg";
    let social_list = props.data && props.data.social ? props.data.social : null;
    let job_title = props.data && props.data.job_title ? props.data.job_title : null;
    let name_complete = props.data && props.data.name ? props.data.name : null;
    let copy_biography = props.data && props.data.biography ? props.data.biography : null;
    let contact_list = props.data && props.data.contacts ? props.data.contacts : null;

    return (
        <section id="about" className="section theme-light dark-bg">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="about-me">
                            <div className="img">
                                <div className="img-in">
                                    <img src={about_me_image} alt="about"/>
                                </div>
                                <div className="nav social-icons justify-content-center">
                                    {
                                        social_list.map((item, index) =>
                                            <ListSocial key={index.toString()}
                                                        href={item.href}
                                                        type={item.type}
                                                        layout={item.layout}/>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="info"><p>{job_title}</p><h3 className={"font-alt"}>{name_complete}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 ml-auto">
                        <div className="about-info">
                            <div className="title"><h3>{props.t('title.biography')}</h3></div>
                            <div className="about-text">
                                {
                                    copy_biography.map((item, index) =>
                                        <p key={index.toString()}>{props.t('bio.'+((index+1).toString()))}</p>
                                    )
                                }
                            </div>
                            <div className="info-list">
                                <div className="row">
                                    {
                                        contact_list.map((item, index) =>
                                            <ListBioContact key={index.toString()}
                                                            type={item.type}
                                                            text={item.text}
                                                            link={item.link}
                                                            value={item.value}
                                                            t={props.t}/>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separated"/>
                <div className="title"><h3>{props.t('title.what_i_do')}</h3></div>
                <motion.div className="row" transition={{ ease: "easeOut", duration: 2 }}>
                    {
                        WHATIDO_LIST.map((item, index) =>
                            <ListWhatIDo key={index.toString()}
                                         href={item.href}
                                         type={item.type}
                                         node={item.node}
                                         translation={props.t}/>
                        )
                    }
                </motion.div>
                <div className="separated"/>
                <div className="title"><h3>{props.t('title.references')}</h3></div>
                <Reference/>
            </div>
        </section>
    );
}

export default About;