const DATA_CONSULTING = {
    job_title: 'Full Stack Developer & Digital technology strategist',
    section_feature: {
        title: 'Why should you need my skills?',
        subtitle: 'My goal is to help companies improve and scale their business processes through a technological and communication implementation.',
        caption: 'Web Developer & Digital Technology Strategist Freelance. I live with an intense passion for software engineering, especially in the world of architecture, functional development and productivity improvement.',
        item:[
            {image: 'https://www.productboard.com/wp-content/uploads/2017/09/illustration_understand.png'},
            {image: 'https://www.productboard.com/wp-content/uploads/2017/09/illustration_prioritize.png'},
            {image: 'https://www.productboard.com/wp-content/uploads/2017/09/illustration_automate.png'},
            {image: 'https://www.productboard.com/wp-content/uploads/2017/09/illustration_monitor.png'},
            {image: 'https://www.productboard.com/wp-content/uploads/2017/09/illustration_earn.png'},
        ]
    },
    section_service: {
        title: 'Are you looking for something more simple?',
        subtitle: 'Advice on your blog, website or eCommerce | enhance your brand - for individuals, bloggers, influencers and freelancers. Develop communication strategies in line with your objectives & dedicated workshops for your business.',
        item: [
            {
                type: 'website_review',
                title: 'Website & Blog Review',
                captions: [
                    'La consulenza offre consigli pratici su come migliorare il tuo sito web o e-commerce per offrire ai tuoi clienti una migliore navigabilità e prestazioni.',
                    'Il pacchetto Website & Blog Review è pensato per chi desidera un confronto oltre che strategico anche operativo consigli pratici e soluzioni operative su come migliorare prestazioni e navigabilità.',
                    'Attraverso l\'incontro preciso e puntuale verrà identificati le criticità e si creerà in modo puntuale una o più possibili strategie, per approfondire e definire in modo puntuale e preciso un piano di sviluppo ed implementazione',
                ],
                settings: [
                    {key: 'duration', value: 'Da 1h a max 4h'},
                    {key: 'mode', value: 'Video-call (Google Meet/Zoom)'},
                    {
                        key: 'bonus',
                        value: 'La consulenza verrà registrata ed inviata al cliente subito dopo l\'incontro.'
                    },
                ],
                price: [
                    {value: '€89/h', iva: '(iva inclusa)'}
                ],
                cta: 'service_cta'
            },
            {
                type: 'business_coach',
                title: 'Business Coach per il tuo Brand',
                captions: [
                    'Ottieni la consulenza su come avviare e creare il tuo brand e quale strategia adottare. Le consulenze affrontano sia temi strategici che operativi e sono strutturate interamente in base alle esigenze del cliente.',
                    'Il pacchetto Business Coach per il tuo Brand è consigliato a chi desidera un supporto maggiormente continuativo sul prorio brand e prevede già di avere bisogno di più di una consulenza sia in ambito di sviluppo tecnologico che in quello comunicativo.',
                    'Innovare, creare e portare valore sia alla persona che al suo business - attraverso fasi di definizione, attuazione e consolidamento.',
                ],
                settings: [
                    {key: 'duration', value: '1 ora x 3 incontri'},
                    {key: 'mode', value: 'Video-call (Google Meet/Zoom)'},
                    {
                        key: 'bonus',
                        value: 'La consulenza verrà registrata ed inviata al cliente subito dopo l\'incontro.'
                    },
                ],
                price: [
                    {value: '€349', iva: '(iva inclusa)'},
                ],
                cta: 'service_cta'
            },
            {
                type: 'social_media_strategist',
                title: 'Social Media Strategist',
                captions: [
                    'Gestione efficace e professionale della pagina e dei contenuti condivisi, coinvolgimento della fanbase e risoluzione in tempo reale delle eventuali “crisi” della comunicazione sui social.',
                    'Definizione degli obiettivi e strategia per poterli perseguirli, questi sono alcuni degli ingredienti che offre questo pacchetto.',
                ],
                settings: [
                    {key: 'duration', value: '5 ore + 1 ora di pianificazione'},
                    {key: 'mode', value: 'Video-call (Google Meet/Zoom)'},
                    {
                        key: 'bonus',
                        value: 'La consulenza verrà registrata ed inviata al cliente subito dopo l\'incontro.'
                    },
                ],
                price: [
                    {value: '€210', iva: '(iva inclusa)'},
                ],
                cta: 'service_cta'
            },
            {
                type: 'workshop',
                title: 'Workshop',
                captions: [
                    'Lezioni e workshop pratici in via telematica, sulla progettazione e creazione della propria piattaforma web (sito web o e-commerce) oltre che sull\'utilizzo dei diversi Social Media.',
                    'I workshop potranno avere un taglio formativo, strategico oppure operativo in base alle esigenze del cliente.'
                ],
                advice: 'Durante la consulenza è possibile porre qualsiasi domanda o richiedere approfondimenti sull\'utilizzo di piattaforme e tools.',
                settings: [
                    {key: 'duration', value: 'Da finire con il cliente'},
                    {key: 'mode', value: 'Video-call (Google Meet/Zoom)'},
                    {
                        key: 'bonus',
                        value: 'La consulenza verrà registrata ed inviata al cliente subito dopo l\'incontro.'
                    },
                ],
                price: [
                    {value: '', iva: '-'},
                ],
                cta: 'service_cta_ask'
            }
        ]
    },
    social: [
        {href: 'https://www.facebook.com/braghin.manuel/', type: 'facebook', layout: 'facebook'},
        {href: 'https://www.instagram.com/bragho/', type: 'instagram', layout: 'instagram'},
        {href: 'https://twitter.com/bragho/', type: 'twitter', layout: 'twitter'},
        {href: 'https://www.linkedin.com/in/manuelbraghin/', type: 'linkedin', layout: 'linkedin'},
        {href: 'https://t.me/bragho', type: 'telegram', layout: 'telegram'}
    ]
};

export default DATA_CONSULTING;