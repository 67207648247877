import './index.css';
import React, {useRef} from "react";
import {motion} from "framer-motion";
import Backdrop from "../backdrop/index";
import emailjs, {init} from "@emailjs/browser";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

const Modal = ({handleClose, item, t}) => {
    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}  // Prevent click from closing modal
                className="modal bg-brand-white"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className="modal-container">
                    <div className="modal-header">
                        <h3 className="font-alt modal-title color-brand-dark">{item.title}</h3>
                        <button type="button" className={"px-btn close"} onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body modal-text">
                        <div className={"row"}>
                            {
                                item.type !== 'consulting' &&
                                <div className={"col-12 col-md-4 details"}>
                                    <div style={{marginBottom: '15px'}}>
                                        {item.settings.map((item, index) =>
                                            <p key={index.toString()} className={"setting"}><strong
                                                className="font-alt color-brand-dark"
                                                style={{marginRight: '5px'}}>{t('service_' + item.key)}:</strong>{item.value}
                                            </p>
                                        )}
                                    </div>
                                    <div className={"price-content"}>
                                        {item.price && item.price.map((item, index) =>
                                            <h3 key={index.toString()}
                                                className={"price font-alt"}>{(item.name ?? '') + ' ' + item.value}
                                                <small>{item.iva}</small></h3>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className={"col-12 " + (item.type !== 'consulting' ? 'col-md-8' : '')}>
                                <ModalForm type={item.type} title={item.title} onclick={handleClose}/>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </Backdrop>
    );
};

const ModalForm = ({type, title}) => {
    const EMAILJS_PUBLIC_KEY = '6yhcOFGk21jkS47fp';
    const EMAILJS_SERVICE_ID = 'service_kfmdzpj';
    const EMAILJS_TEMPLATE_ID = 'template_haxshk9';
    init(EMAILJS_PUBLIC_KEY);
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, form.current, EMAILJS_PUBLIC_KEY).then(
            (result) => {
                e.target.reset();
            },
            (error) => {
                console.log(error.text);
            }
        );
    };

    return (
        <form className={"modal-form " + (type === 'consulting' ? 'text-align-left' : '')} onSubmit={handleSubmit} ref={form}>
            <p>👋🏽 Hola, stai per richiedere la tua consulenza per il servizio <strong className={"font-alt"}>{title}</strong>.</p>
            <p>
                Inserisci qui il tuo nome
                <input type={"text"} name="applicant_name" placeholder={"Scrivi il tuo nome e cognome"}/>
                e qui la tua miglior email <input type={"text"} name="applicant_email" placeholder={"Scrivi qui la tua email"}/>
                ti contatterò non appena la tua richiesta arriverà sulla mia inbox.
            </p>
            <p>Descrivi in poche righe in cosa posso esserti utile ed il motivo per il quale richiedi la la consulenza per {title}.</p>
            <textarea rows={5} name="applicant_note"/>
            <p style={{margin: 0}}>Clicca su <i>Invia</i> per essere contattato senza impegno!</p>
            <p>Ci vediamo dall'altra parte 🚀.</p>
            <input type={"hidden"} name="consulting_type" value={type}/>
            <ModalButton label="Invia"/>
        </form>
    )
}

const ModalButton = ({label}) => (
    <motion.button className="px-btn modal-button" type="submit"
                   whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>{label}
    </motion.button>
);

export default Modal;