const BIO_COPY = {
    name_complete: 'ManuelBraghin',
    job_title: 'Full Stack Developer & Digital technology strategist',
    mission: 'mission',
    about: {
        name: 'Manuel Braghin',
        job_title: 'Full Stack Developer & Digital technology strategist',
        image: '/images/mb-photo-profile.jpeg',
        biography: ['bio.1','bio.2','bio.3','bio.4','bio.5'],
        contacts: [
            {type: 'link', text:'email', value: 'hola@manuelbraghin.com', link:'mailto:hola@manuelbraghin.com'},
            {type: 'link', text:'headquarter', value: 'IDEA Coworking & Hub', link:'https://www.ideacoworking.it/'},
            {type: 'link', text:'blog', value: 'bragholand.com', link:'https://bragholand.com/'},
            {type: 'text', text:'freelance', value: 'Available'},
        ],
        social: [
            {href: 'https://www.facebook.com/braghin.manuel/', type: 'facebook', layout: 'facebook'},
            {href: 'https://www.instagram.com/bragho/', type: 'instagram', layout: 'instagram'},
            {href: 'https://twitter.com/bragho/', type: 'twitter', layout: 'twitter'},
            {href: 'https://www.linkedin.com/in/manuelbraghin/', type: 'linkedin', layout: 'linkedIn'},
            {href: 'https://t.me/bragho', type: 'telegram', layout: 'telegram'}
        ]
    }
};

export default BIO_COPY;